<template>
  <article-card-generic
    class="tool-card"
    :class="{ highlighted: isHighlighted }"
  >
    <div class="col-one">
      <cms-components-shared-image
        fit-cover
        :image="tool.icon"
        size="iconlarge"
      />
    </div>
    <div class="col-two">
      <div class="title">
        {{ tool.title }}
      </div>
      <cms-components-rich-text
        :class="{ hide: !isHighlighted }"
        class="description"
        :text="tool.description"
      />
      <cms-components-shared-link
        :link="tool.cta"
        type="link"
        @link-click="handleCtaClick"
      />
    </div>
  </article-card-generic>
</template>

<script>
import { useScreenSizeStore } from '@hz/ui-kit'

import { trackCmsToolCtaClick } from '@/helpers/conversion/conversion-cms'
import { mapState } from 'pinia'

export default {
  props: {
    tool: {
      type: Object,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useScreenSizeStore, ['isMobileWidth']),
    isHighlighted() {
      return !this.isMobileWidth && this.highlighted
    },
  },
  inject: {
    parentCategory: {
      from: 'parentCategory',
      default: {
        title: '',
      },
    },
    childCategory: {
      from: 'childCategory',
      default: {
        title: '',
      },
    },
  },
  methods: {
    handleCtaClick() {
      trackCmsToolCtaClick(this.tool.title, this.parentCategory.title, this.childCategory?.title)
    },
  },
}
</script>

<style lang="less" scoped>
.tool-card {
  &.card {
    flex-direction: row;
  }

  height: 72px;
  padding: 12px 22px;
  transition: background-color 0.35s ease;

  .col-one {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  .col-two {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  :deep(.cms-image) {
    width: 38px;
    height: 38px;
  }

  .title {
    font-weight: var(--ds-weight-semi-bold);
    font-size: 15px;
    color: var(--ds-color-gray-100);
  }

  :deep(.cms-link) {
    font-weight: 500;
    font-size: 14px;
  }

  &:hover {
    background-color: var(--ds-color-primary-25);

    :deep(.cms-link) {
      text-decoration: underline !important;
    }
  }

  &.highlighted {
    flex-direction: column;
    height: 240px;
    padding: 16px;

    .col-one {
      justify-content: center;
    }

    .col-two {
      flex: 1;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }

    :deep(.cms-image) {
      width: 110px;
      height: 110px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      color: var(--ds-color-gray-50);
    }

    .title {
      font-weight: var(--ds-weight-semi-bold);
      font-size: 16px;
    }
  }
}
</style>
