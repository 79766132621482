<template>
  <Section
    v-if="featuredTools.length && otherTools.length"
    class="tool-bar"
    wide-mode
    small-space
    :no-space="noSpace"
  >
    <h2 class="title">{{ tools.title }}</h2>
    <div class="wrapper">
      <div class="featured-tools">
        <template v-for="(tool, index) in featuredTools" :key="index">
          <article-card-tool highlighted :tool="tool" />
        </template>
      </div>
      <div class="tools">
        <template v-for="(tool, index) in otherTools" :key="index">
          <article-card-tool :tool="tool" />
        </template>
      </div>
    </div>
  </Section>
</template>

<script>
export default {
  props: {
    tools: {
      type: Object,
      required: true,
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    featuredTools() {
      const featuredTools = []

      if (this.tools.featured_tool_one) {
        featuredTools.push(this.tools.featured_tool_one)
      }

      if (this.tools.featured_tool_two) {
        featuredTools.push(this.tools.featured_tool_two)
      }

      return featuredTools
    },
    otherTools() {
      const otherTools = []

      if (this.tools.tool_three) {
        otherTools.push(this.tools.tool_three)
      }

      if (this.tools.tool_four) {
        otherTools.push(this.tools.tool_four)
      }

      if (this.tools.tool_five) {
        otherTools.push(this.tools.tool_five)
      }

      return otherTools
    },
  },
}
</script>

<style lang="less" scoped>
.tool-bar {
  padding-top: 62px;
  padding-bottom: 22px;
  background-color: var(--ds-color-primary-100);

  .title {
    font-weight: var(--ds-weight-semi-bold);
    font-size: 24px;
    text-align: center;
    color: var(--ds-color-white-100);
    margin-bottom: 32px;
  }
}

.tool-bar.section.wide-mode :deep(.section-container) {
  padding-left: 26px;
  padding-right: 26px;

  @media @bp-desktop {
    padding-left: 100px;
    padding-right: 100px;
  }
}

:deep(.tool-card) {
  margin-bottom: 10px;
}

@media @bp-desktop {
  .tool-bar {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
  }

  .title {
    font-size: 32px;
  }

  .featured-tools {
    display: flex;
    flex-direction: row;
    flex-basis: 66.66%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    padding-right: 12px;

    :deep(.tool-card) {
      margin-bottom: 0;

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .tools {
    display: flex;
    flex-basis: 33.33%;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 12px;

    :deep(tool-card:last-child) {
      margin-bottom: 0;
    }
  }
}
</style>
